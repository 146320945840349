/* eslint-disable max-len */
import { envVal } from '@root/config/config';
import moment from 'moment';
import { getStreamPlaceOrEvent } from "@kursk/content/contentUtils";
import { capitalize } from 'lodash';

const year = new Date().getFullYear();

const tagPageDefaultSeo = {
  title: ({ tagName }) => `${tagName[0].toUpperCase() + tagName.slice(1)} во Владимире ${year}. Официальный сайт - Золотые ворота культуры`,
  description: ({ tagName }) => `${tagName[0].toUpperCase() + tagName.slice(1)} во Владимире. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт - Владимирской области`,
  titleOg: () => '',
  h1: ({ tagName }) => tagName
};
const tagPageDefaultSeoEvents = {
  title: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} во Владимире ${year}. Официальный сайт - Золотые ворота культуры`,
  description: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} во Владимире. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт - Владимирской области`,
  titleOg: () => '',
};
const tagPageDefaultSeoPlaces = {
  title: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} Владимира. Официальный сайт - Золотые ворота культуры`,
  description: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} во Владимире. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт - Владимирской области`,
  titleOg: () => '',
};

export const vladimirContent = {
  timezone: 'Europe/Moscow',
  yandexMapState: {
    center: [56.129057, 40.406635],
    zoom: 9,
  },
  locale: { _id: 180 },
  title: 'Золотые ворота культуры',
  subTitle: 'Культурный портал Владимирской области.',
  mainBanner: {
    hide: true,
    title: '',
    text: '',
    links: []
  },
  useBvi: false,
  phone: '8 800 301-61-96',
  mail: 'support@vladimirculture.ru',
  agreementLink: '/public_agreement',
  socials: {
    ok: 'https://ok.ru/group/70000005056096',
    vk: 'https://vk.com/vladimirculture',
    yaDzen: 'https://dzen.ru/user/9ugrcg9680jhmft4jyq7dfmv6g',
    telegram: 'https://t.me/vladimirculture',
    facebook: '',
    instagram: '',
  },
  socialTitles: {
    vk: 'вконтакте',
    telegram: 'Telegram',
  },
  subscribeForm: {
    title: 'Узнавайте новости культуры первыми',
  },
  header: {
    links: [
      {
        title: 'Афиша',
        relativeLink: '/events',
        links: [
          { title: 'Все события', to: '/events' },
          { title: 'Пушкинская карта', to: '/events/tag-pushkinskaya-karta' },
          { title: 'Концерты и шоу', to: '/events/tag-koncerty-i-shou' },
          { title: 'Спектакли', to: '/events/tag-spektakli' },
          { title: 'Выставки', to: '/events/tag-vystavki' },
          { title: 'Мастер-классы', to: '/events/tag-master-klassy' },
          { title: 'Кино', to: '/movies' },
          { title: 'Фестивали', to: '/events/tag-festivali' },
          { title: 'Праздники', to: '/events/tag-prazdniki' }
        ]
      },
      { title: 'Места', to: '/places' },
      { title: 'Маршруты', to: '/touristroutes' },
      { title: 'Обзоры', to: '/overviews' },
      {
        title: 'Live',
        links: [
          { to: '/streams', title: 'Трансляции' },
          { to: '/artefacts', title: 'Дополненная реальность' },
          { to: '/museums', title: 'Виртуальные музеи' },
        ],
      },
      {
        title: 'Еще',
        links: [
          { title: 'Кафе и рестораны', to: '/restaurants' },
          { title: 'Гостиницы', to: '/hotels' }
        ]
      },
    ],
  },
  footer: {
    title: 'Золотые ворота культуры. Владимирская область',
    systemName: `© ${year}. Золотые ворота культуры. Культурный портал Владимирской области. Все права защищены.`,
    showProCultureLogo: true,
    links: [
      { title: 'Афиша', to: '/events' },
      { title: 'Абонементы', to: '/ticketsbundles' },
      { title: 'Места', to: '/places' },
      { title: 'Маршруты', to: '/touristroutes' },
      { title: 'Обзоры', to: '/overviews' },
      { title: 'Гостиницы', to: '/hotels' },
      { title: 'Рестораны', to: '/restaurants' },
    ],
    callTime: 'В будние дни с 9:00 до 18:00'
  },
  analytics: {
    yandex: envVal({ production: '95854527' }, null),
    gtm: envVal({ production: 'GTM-MD66FX92' }, null),
    pixel: envVal({ production: '31531' }, null),
    goals: {
      paidVisit: 'events_visit_vladimir',
      paidBuy: 'buy_ticket_vladimir',
      freeVisit: 'events_visits_vladimir',
      freeNoTicketsVisit: 'noactive_free_vladimir',
      freeBuy: 'freeandzero_vladimir',
      externalLinkVisit: 'events_visit_redirect_vladimir',
      externalLinkBuy: 'buy_redirect_vladimir',
      ticketsAdd: 'entity_ticket_add',
      registration: 'kolvo_registration',
    }
  },
  unisenderNewsPopup: {
    id: envVal({ staging: '', }, null),
  },
  componentsConfig: {
    header: 'default',
    footer: 'default',
    heroBlock: 'banner',
    socialCardIconVk: 'v2',
    socialCardIconTg: 'v2',
  },
  seo: {
    pages: {
      index: {
        title: () => 'Культурный портал Владимирской области - Золотые ворота культуры. Vladimirculture.ru',
        description: () => 'Культурный портал Владимирской области: события, места, маршруты, обзоры, гостиницы и рестораны, трансляции и виртуальные музеи',
        titleOg: () => 'Культурный портал Владимирской области - Золотые ворота культуры. Vladimirculture.ru'
      },
      publicAgreement: {
        title: () => 'Политика конфиденциальности и публичная оферта',
        description: () => 'Политика конфиденциальности и публичная оферта',
      },
      events: {
        title: () => `Куда сходить во Владимире ${year}. Афиша ближайших мероприятий Владимира. Интересные развлекательные события. Официальный сайт - Золотые ворота культуры`,
        description: () => 'Куда можно пойти во Владимире. Афиша интересных мероприятий во Владимирской области. Подробная информация о событиях: описания, места, расписания, ☎ телефоны. Официальный сайт - Владимирской области',
        titleOg: () => '',
      },
      'tagPageDefaultSeoevents': tagPageDefaultSeoEvents,
      places: {
        title: () => `Все интересные места и достопримечательности Владимира ${year}. Официальный сайт - Золотые ворота культуры`,
        description: () => `Интересные и необычные достопримечательности во Владимире ${year} — описания, цены на билеты, адреса, режим работы, фотографии, адреса на карте. Официальный сайт - Владимирской области`,
        titleOg: () => ''
      },
      'tagPageDefaultSeoplaces': tagPageDefaultSeoPlaces,
      'tagPageDefaultSeo': tagPageDefaultSeo,
      routes: {
        title: () => `Туристические маршруты во Владимирской области ${year}. Официальный сайт - Золотые ворота культуры`,
        description: () => `Лучшие туристические маршруты во Владимире, которые можно пройти пешком или проехать на автомобиле. Карты туристических маршрутов, описание, необычные идеи для путешествия во Владимире. Официальный сайт - Владимирской области`,
        titleOg: () => ''
      },
      overviews: {
        title: () => `Обзоры ${year} — Владимирская область. Официальный сайт - Золотые ворота культуры`,
        description: () => `Обзоры лучших событий, мест ${year} — Владимир. Официальный сайт - Владимирской области`,
        titleOg: () => ''
      },
      regionvideos: {
        title: () => `Видео о Владимирской области, ${year}. Официальный сайт - Золотые ворота культуры`,
        description: () => `Видео о Владимирской области, ${year}. Официальный сайт - Владимирской области`,
        titleOg: () => '',
      },
      hotels: {
        title: () => `Гостиницы во Владимирской области ${year}. Официальный сайт - Золотые ворота культуры`,
        description: () => 'Гостиницы во Владимире. Подробная информация: описания, адрес, режимы работы, фото, адреса на карте, рейтинги. Официальный сайт - Владимирской области',
        titleOg: () => ''
      },
      restaurants: {
        title: () => `Кафе и рестораны во Владимирской области ${year}. Официальный сайт - Золотые ворота культуры`,
        description: () => 'Рестораны и кафе во Владимире. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Официальный сайт - Владимирской области',
        titleOg: () => ''
      },
      streams: {
        title: () => 'Трансляции на сайте. Официальный сайт - Золотые ворота культуры',
        description: () => 'Все трансляции и площадки их проведения, виртуальные залы, виртуальные музеи, события. Видео о регионе. Официальный сайт - Владимирской области',
        titleOg: () => '',
        h1: () => `Трансляции - Владимирская область ${year}`,
      },
      ticketsBundles: {
        title: () => 'Абонементы. Официальный сайт - Золотые ворота культуры',
        description: () => 'Абонементы во Владимирской области. Официальный сайт - Владимирской области',
        titleOg: () => ''
      },
      movies: {
        title: () => `Киноафиша во Владимире | Афиша мероприятий с точными датами на ${year} год. Официальный сайт - Золотые ворота культуры`,
        description: () => `Киноафиша во Владимире на ${year} год ✅ Афиша интересных мероприятий во Владимирской области. Подробная информация о событиях: ⭐ описания, места, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
        titleOg: () => ''
      },
      singleEvent: {
        title: ({ entity }) => `${entity.name} — описание, программа мероприятия, дата, время.
         Адрес места проведения — ${entity.address}. Официальный сайт - Золотые ворота культуры`,
        description: ({ entity }) => `${entity.name}. ✅ Место проведения — ${entity.placeName},
         ✅ дата проведения — ${entity.eventDate ? moment(entity.eventDate).format('D MMMM YYYY') : ''}, ✅ адрес места проведения — ${entity.address}.
          Подробная информация: описание, фото. Официальный сайт — Владимирской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singlePlace: {
        title: ({ entity }) => `${entity.name}. Адрес — ${entity.address}.
        Фото, режим работы, афиша и события. Официальный сайт - Золотые ворота культуры`,
        description: ({ entity }) => `${entity.name}. Адрес — ${entity.address} Подробная информация: описание,
         адрес на карте, время работы, телефон, фотографии.
          Все туристические места и достопримечательности на сайте — Владимирской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleRoute: {
        title: ({ entity }) => `${entity.name}. Подробная информация: описание,
         адреса мест на карте, фото. Официальный сайт - Золотые ворота культуры`,
        description: ({ entity }) => `${entity.name}. Лучшие туристические маршруты,
         которые можно исходить пешком или проехать на автомобиле. Официальный сайт - Владимирской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleOverview: {
        title: ({ entity }) => `${entity.name}. Официальный сайт - Золотые ворота культуры`,
        description: ({ entity }) => `Обзор - ${entity.name}. Официальный сайт Владимирской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleRegionVideo: {
        title: ({ entity }) => `Видео о Владимирской области, ${year}. ${entity.name}. Официальный сайт - Золотые ворота культуры`,
        description: ({ entity }) => `Видео о Владимирской области, ${year}. ${entity.name}. Официальный сайт - Владимирской области`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleHotel: {
        title: ({ entity }) => `Гостиница ${entity.name}. Адрес — ${entity.address}.
         Фото, режим работы. Официальный сайт - Золотые ворота культуры`,
        description: ({ entity }) => `Гостиница ${entity.name}. Адрес — ${entity.address} Подробная информация:
         описание, адрес на карте, время работы, телефон, фотографии. Все гостиницы на сайте — Владимирской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleRestaurant: {
        title: ({ entity }) => `Ресторан ${entity.name}. Адрес — ${entity.address}.
         Фото, режим работы. Официальный сайт - Золотые ворота культуры`,
        description: ({ entity }) => `Ресторан ${entity.name}. Адрес — ${entity.address} Подробная информация:
         описание, адрес на карте, время работы, телефон, фотографии. Все рестораны на сайте — Владимирской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleStream: {
        title: ({ entity }) => `Трансляция ${entity.name}. ${getStreamPlaceOrEvent(entity)}Прямая трансляция на сайте - Золотые ворота культуры`,
        description: ({ entity }) => `${entity.name}. ${getStreamPlaceOrEvent(entity)}Дата и время проведения — ${entity.liveDate ? moment(entity.liveDate).format('D MMMM YYYY H:mm') : ''} на официальном сайте Владимирской области`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleTicketsBundle: {
        title: ({ entity }) => `Абонемент ${entity?.name}. Официальный сайт - Золотые ворота культуры`,
        description: ({ entity }) => `Абонемент ${entity?.name}. Официальный сайт - Владимирской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleMovie: {
        title: ({ entity }) => `Фильм ${entity?.name} во Владимире  — афиша, цена на билеты,
         расписание, описание, место проведения. Официальный сайт - Золотые ворота культуры`,
        description: () => `Киноафиша Владимира с точным расписанием киносеансов во всех
         кинотеатрах города. Узнать цену и купить билет можно на странице с выбором фильма.
          На сайте можно узнать точное время и дату на сегодня, завтра или в другой день`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      pushkincard: {
        title: () => `Пушкинская карта — АИС «Золотые ворота культуры»`,
        description: () => '',
        titleOg: () => `Пушкинская карта — АИС «Золотые ворота культуры»`,
      },
      main: { title: 'Владимир. Золотые ворота культуры' },
      museums: {
        title: () => 'Виртуальные музеи Владимирской области. Официальный сайт - Золотые ворота культуры',
        description: () => 'Виртуальные музеи во Владимире. Официальный сайт - Владимирской области'
      },
      singleMuseum: {
        title: ({ entity }) => `Виртуальные музеи. ${entity.name}. Официальный сайт - Золотые ворота культуры`,
        description: ({ entity }) => `Виртуальные музеи. ${entity.name}. Официальный сайт — Владимирской области`
      },
      halls: {
        title: () => `Виртуальные концертные залы, ${year} — Владимир и Владимирская область. Официальный сайт - Золотые ворота культуры`,
        description: () => `Виртуальные концертные залы, ${year}. Владимир и Владимирская область. Официальный сайт - Владимирской области`
      },
      singleHall: {
        title: ({ entity }) => `Виртуальные концертные залы, ${year}, ${entity.name}. Владимир и Владимирская область. Официальный сайт - Золотые ворота культуры`,
        description: ({ entity }) => `Виртуальные концертные залы, ${year}, ${entity.name}. Владимир и Владимирская область. Официальный сайт - Владимирской области`
      },
      artefacts: {
        title: () => 'Дополненная реальность. Официальный сайт - Золотые ворота культуры',
        description: () => 'Дополненная реальность. Официальный сайт - Владимирской области'
      },
      singleArtefact: {
        title: ({ entity }) => `${entity?.name}. Официальный сайт - Золотые ворота культуры`,
        description: ({ entity }) => `${entity?.name}. Официальный сайт — Владимирской области`,
      },
      search: {
        title: ({ searchQuery }) => `Поиск событий, мест, маршрутов, обзоров,
        ресторанов, гостиниц, абонементов по запросу «${searchQuery}»`,
        description: ({ searchQuery }) => `События, места, маршруты, обзоры,
        рестораны, гостиницы, абонементы по запросу «${searchQuery}»`,
      },
      faq: {
        title: () => `Часто задаваемые вопросы. Официальный сайт - Золотые ворота культуры`,
        description: () => 'Часто задаваемые вопросы. Официальный сайт - Владимирской области',
      },
      // Порядок вложенности необходимо соблюдать, иначе функция получения сео метаданных отработает неправильно
      // В единсвенном числе - фильтр (locale, place, date, ...), с приставкой Page(eventsPage, hotelsPage, ...) - страница, для которой метаданные
      // Примеры:
      // locale->date->eventsPage: Фильтр район + фильтр дата для страницы событий
      // place->tag->eventsPage->default: Фильтр место + тэг для страницы событий
      // tag->eventsPage->pushkinskaya-karta: Тэг Пушкцинская карта для страницы событий
      filters: {
        locale: {
          eventsPage: {
            title: ({ localeName }) => `${localeName} - афиша ${year}. Интересные развлекательные события. Официальный сайт - Золотые ворота культуры`,
            description: ({ localeName }) => `${localeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
            h1: ({ localeName }) => `${localeName} - афиша`,
          },
          placesPage: {
            title: ({ localeName }) => `Интересные места - ${localeName} ${year}. Официальный сайт - Золотые ворота культуры`,
            description: ({ localeName }) => `Все интересные места и достопримечательности - ${localeName} ${year} — описания, цены на билеты, режим работы, фотографии, адреса на карте. Официальный сайт - Владимирской области`,
            h1: ({ localeName }) => `Места - ${localeName}`,
          },
          restaurantsPage: {
            title: ({ localeName }) => `Кафе и рестораны - ${localeName} ${year}. Официальный сайт - Золотые ворота культуры`,
            description: ({ localeName }) => `Кафе и рестораны - ${localeName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Официальный сайт - Владимирской области`,
            h1: ({ localeName }) => `Кафе и рестораны - ${localeName}`
          },
          hotelsPage: {
            title: ({ localeName }) => `Гостиницы и отели - ${localeName} ${year}. Официальный сайт - Золотые ворота культуры`,
            description: ({ localeName }) => `Гостиницы и отели - ${localeName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги, парковка, завтраки, спа и т. д. Официальный сайт - Владимирской области`,
            h1: ({ localeName }) => `Гостиницы и отели - ${localeName}`
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(localeName)} - ${tagName}. Интересные развлекательные события. Официальный сайт - Золотые ворота культуры`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о мероприятиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт - Владимирской области`,
                h1: ({ localeName, tagName }) => `${capitalize(localeName)} - ${tagName}`,
              }
            },
            placesPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Официальный сайт - Золотые ворота культуры`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт - Владимирской области`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            },
            hotelsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Официальный сайт - Золотые ворота культуры`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт - Владимирской области`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            },
            restaurantsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Официальный сайт - Золотые ворота культуры`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт - Владимирской области`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            }
          },
          place: {
            eventsPage: {
              title: ({ placeName }) => `${placeName} - афиша ${year}. Интересные развлекательные события. Официальный сайт - Золотые ворота культуры`,
              description: ({ placeName }) => `${placeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
              h1: ({ placeName }) => `${placeName} - афиша`,
            }
          },
          date: {
            eventsPage: {
              title: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date} ${year} года. Интересные развлекательные события. Официальный сайт - Золотые ворота культуры`,
              description: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date} ${year} года. Интересные мероприятия во Владимирской области. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
              h1: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date}`,
            }
          },
          interim: {
            eventsPage: {
              title: ({ localeName, interim, formattedDate, formattedYear }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные события. Официальный сайт - Золотые ворота культуры`,
              description: ({ localeName, interim, formattedDate, formattedYear }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
              h1: ({ localeName, interim, formattedDate }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate}`,
            }
          }
        },
        place: {
          eventsPage: {
            title: ({ placeName }) => `${placeName} - афиша ${year}. Интересные развлекательные события. Официальный сайт - Золотые ворота культуры`,
            description: ({ placeName }) => `${placeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
            h1: ({ placeName }) => `${placeName} - афиша`,
          },
          streamsPage: {
            title: ({ placeName }) => `Трансляции - ${placeName} ${year}. Официальный сайт - Золотые ворота культуры`,
            description: ({ placeName }) => `Трансляции - ${placeName} ${year}. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт - Владимирской области`,
            h1: ({ placeName }) => `Трансляции - ${placeName}`
          },
          date: {
            eventsPage: {
              title: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date} ${year} года. Интересные развлекательные события. Официальный сайт - Золотые ворота культуры`,
              description: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date} ${year} года. Интересные мероприятия во Владимирской области. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
              h1: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date}`
            },
            streamsPage: {
              title: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date} ${year} года. Интересные развлекательные трансляции. Официальный сайт - Золотые ворота культуры`,
              description: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date} ${year} года. Интересные трансляции - Владимирская область. Подробная информация о трансляциях: описания, время проведения, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
              h1: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date}`
            }
          },
          interim: {
            eventsPage: {
              title: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные события. Официальный сайт - Золотые ворота культуры`,
              description: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
              h1: ({ placeName, interim, formattedDate }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate}`,
            },
            streamsPage: {
              title: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные трансляции. Официальный сайт - Золотые ворота культуры`,
              description: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Интересные трансляции - Владимирская область. Подробная информация о трансляциях: описания, время проведения, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
              h1: ({ placeName, interim, formattedDate }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate}`,
            }
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}. Интересные развлекательные события. Официальный сайт - Золотые ворота культуры`,
                description: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}. Подробная информация о мероприятиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт - Владимирской области`,
                h1: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}`,
              }
            }
          }
        },
        date: {
          eventsPage: {
            title: ({ formattedDate }) => `Афиша Владимира на ${formattedDate} года. Интересные развлекательные события во Владимире. Официальный сайт - Золотые ворота культуры`,
            description: ({ formattedDate }) => `Афиша в Владимире на ${formattedDate} года. Интересные мероприятия Владимира. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
            h1: ({ date }) => `Афиша на ${date}`,
          },
          moviesPage: {
            title: ({ formattedDate }) => `Киноафиша во Владимире на ${formattedDate}. Кино во Владимире - ${formattedDate}. Официальный сайт - Золотые ворота культуры`,
            description: ({ formattedDate }) => `Кино во Владимире - ${formattedDate} ✅ Киноафиша во Владимире на ${formattedDate}. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
            h1: ({ date }) => `Киноафиша на ${date}`,
          },
          streamsPage: {
            title: ({ formattedDate }) => `Трансляции ${formattedDate} года. Официальный сайт - Золотые ворота культуры`,
            description: ({ formattedDate }) => `Трансляции - ${formattedDate} года. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт - Владимирской области`,
            h1: ({ date }) => `Трансляции - ${date}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, formattedDate }) => `${capitalize(tagName)} - афиша на ${formattedDate} года. Интересные развлекательные события. Официальный сайт - Золотые ворота культуры`,
                description: ({ tagName, formattedDate }) => `${capitalize(tagName)} - афиша на ${formattedDate} года. Интересные мероприятия - Владимирская область. Подробная информация о мероприятиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
                h1: ({ tagName, date }) => `${capitalize(tagName)} - афиша на ${date}`,
              }
            },
          },
          genre: {
            moviesPage: {
              title: ({ genreName, formattedDate }) => `Киноафиша в жанре ${genreName} на ${formattedDate} года - Владимирская область. Официальный сайт - Золотые ворота культуры`,
              description: ({ genreName, formattedDate }) => `Кино в жанре ${genreName} на ${formattedDate} года - Владимирская область. Киноафиша на ${formattedDate} года - Владимирская область. Подробная информация о кино: описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
              h1: ({ genreName, date }) => `Киноафиша в жанре ${genreName} на ${date}`,
            }
          }
        },
        interim: {
          eventsPage: {
            title: ({ interim, formattedDate }) => `Афиша на ${interim}, ${formattedDate} во Владимире. Интересные развлекательные события во Владимире. Официальный сайт - Золотые ворота культуры`,
            description: ({ interim, formattedDate }) => `Афиша Владимира на ${interim}, ${formattedDate}. Интересные мероприятия Владимира. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
            h1: ({ interim, formattedDate }) => `Афиша на ${interim}, ${formattedDate}`,
          },
          moviesPage: {
            title: ({ interim, formattedDate, formattedYear }) => `Киноафиша на ${interim}, ${formattedDate} ${formattedYear} года во Владимире. Кино во Владимире ${interim}, на ${formattedDate} ${formattedYear} года. Официальный сайт - Золотые ворота культуры`,
            description: ({ interim, formattedDate, formattedYear }) => `Кино во Владимире на ${interim}, ${formattedDate} ${formattedYear} года ✅ Киноафиша во Владимире ${interim}, ${formattedDate} ${formattedYear} года. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
            h1: ({ interim, formattedDate }) => `Киноафиша на ${interim}, ${formattedDate}`,
          },
          streamsPage: {
            title: ({ interim, formattedDate, formattedYear }) => `Трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Официальный сайт - Золотые ворота культуры`,
            description: ({ interim, formattedDate, formattedYear }) => `Трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт - Владимирской области`,
            h1: ({ interim, formattedDate }) => `Трансляции на ${interim}, ${formattedDate}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate} ${year} года. Интересные развлекательные события. Официальный сайт - Золотые ворота культуры`,
                description: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate} ${year} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
                h1: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate}`,
              }
            },
          },
          genre: {
            moviesPage: {
              title: ({ genreName, interim, formattedDate }) => `Киноафиша в жанре ${genreName} на ${interim}, ${formattedDate} ${year} года - Владимирская область. Официальный сайт - Золотые ворота культуры`,
              description: ({ genreName, interim, formattedDate }) => `Кино в жанре ${genreName} на ${interim}, ${formattedDate} ${year} года - Владимирская область. Киноафиша на ${interim} ${formattedDate} ${year} года - Владимирская область. Подробная информация о кино: описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
              h1: ({ genreName, interim, formattedDate }) => `Киноафиша в жанре ${genreName} на ${interim}, ${formattedDate}`,
            }
          }
        },
        tag: {
          eventsPage: {
            'pushkinskaya-karta': {
              title: () => `Пушкинская карта ${year} во Владимире. Купить билеты по Пушкинской карте во Владимире. Официальный сайт - Золотые ворота культуры`,
              description: () => `Афиша мероприятий по Пушкинской карте Владимира. Купить билеты по Пушкинской карте в кино, на концерты, на спектакли и другие мероприятия. Официальный сайт - Владимирской области`,
              h1: () => `Пушкинская карта`
            },
            'koncerty-i-shou': {
              title: () => `Концерты во Владимире ${year}. Афиша концертов Владимира. Официальный сайт - Золотые ворота культуры`,
              description: () => `Расписания концертов во Владимире. Купить билеты на концерты во Владимире. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт - Владимирской области`,
              h1: () => `Концерты и шоу`
            },
            'spektakli': {
              title: () => `Спектакли во Владимире ${year}. Купить билеты на спектакль во Владимире. Официальный сайт - Золотые ворота культуры`,
              description: () => `Афиша спектаклей во Владимире. Купить билеты на спектакль во Владимире. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт - Владимирской области`,
              h1: () => `Спектакли`
            },
            'detyam': {
              title: () => `Детская афиша во Владимире ${year}. Куда пойти с ребенком во Владимире. Официальный сайт - Золотые ворота культуры`,
              description: () => `Детские мероприятия во Владимире. Куда пойти с ребенком во Владимире. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт - Владимирской области`,
              h1: () => `Детям`
            },
            default: tagPageDefaultSeo
          },
          streamsPage: {
            default: {
              title: ({ tagName }) => `Трансляции - ${tagName}. Официальный сайт - Золотые ворота культуры`,
              description: ({ tagName }) => `Трансляции - ${tagName}. Прямые трансляции: смотреть онлайн и бесплатно Официальный сайт - Владимирской области`,
              h1: ({ tagName }) => `Трансляции - ${tagName}`,
            }
          },
          restaurantsPage: {
            default: {
              title: ({ tagName }) => `${tagName} Владимира ${year}. Официальный сайт - Золотые ворота культуры`,
              description: ({ tagName }) => `${tagName} во Владимире. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Официальный сайт - Владимирской области`,
              h1: ({ tagName }) => tagName,
            }
          },
          hotelsPage: {
            default: {
              title: ({ tagName }) => `Гостиницы и отели - ${tagName} ${year}. Официальный сайт - Золотые ворота культуры`,
              description: ({ tagName }) => `Гостиницы и отели - ${tagName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги, парковка, завтраки, спа и т. д. Официальный сайт - Владимирской области`,
              h1: ({ tagName }) => `Гостиницы и отели - ${tagName}`,
            }
          }
        },
        genre: {
          moviesPage: {
            title: ({ genreName }) => `Киноафиша во Владимире, жанр - ${genreName}. Кино во Владимире, жанр - ${genreName}. Официальный сайт - Золотые ворота культуры`,
            description: ({ genreName }) => `Кино во Владимире, жанр - ${genreName} ✅ Киноафиша во Владимире, жанр - ${genreName}. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт - Владимирской области`,
            h1: ({ genreName }) => `Киноафиша, жанр - ${genreName}`
          },
        }
      },
    },
  },
  homePageBlocks: [
    'best',
    'events',
    'subscribe',
    'places',
    'overviews',
    'routes',
    'socials',
    'restaurants',
    'hotels',
    'ticketsBundles'
  ]
};
